
// ::-webkit-scrollbar {
//   height: 10px;
//   width: 10px;
//   z-index: 12;
//   overflow: auto;
//   background: transparent;
// }

::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  //border-radius: 10px;
  z-index: 12;
  //border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  transition: background-color 0.32s ease-in-out;
  margin: 4px;
  min-height: 32px;
  min-width: 32px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}
