@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@import './colors.scss';

@font-face {
  font-family: Poppins;
  src: url(./fonts/Poppins-Regular.ttf); // Ver fuente
}

@font-face {
  font-family: PoppinsLight;
  src: url(./fonts/Poppins-Light.ttf); // Ver fuente
}

@font-face {
  font-family: PoppinsMedium;
  src: url(./fonts/Poppins-Medium.ttf); // Ver fuente
}

@font-face {
  font-family: PoppinsBold;
  src: url(./fonts/Poppins-Bold.ttf); // Ver fuente
}

@font-face {
  font-family: PoppinsSemiBold;
  src: url(./fonts/Poppins-SemiBold.ttf); // Ver fuente
}

@font-face {
  font-family: OpenSans;
  src: url(./fonts/OpenSans-Regular.ttf); // Ver Fuente
}

@font-face {
  font-family: OpenSansBold;
  src: url(./fonts/OpenSans-Bold.ttf); // Ver Fuente
}

// size-lg == 1440 witch is the same as figma design
$fonts: (
  'h1': (
    'size-lg': 64px,
    // Same as figma
    'size-md': 52px,
    'size-sm': 46px,
    'size-xs': 40px,
    'height': 96px,
    //Same as figma
    'weight': 700,
    //Same as figma
    'style': normal,
    //Same as figma
    'family': Poppins,
    //Same as figma
  ),
  'h2': (
    'size-lg': 48px,
    //Same as figma
    'size-md': 42px,
    'size-sm': 36px,
    'size-xs': 24px,
    'height': 72px,
    //Same as figma
    'weight': 700,
    //Same as figma
    'style': normal,
    //Same as figma
    'family': Poppins,
    //Same as figma
  ),
  'h3': (
    'size-lg': 36px,
    //Same as figma
    'size-md': 32px,
    'size-sm': 28px,
    'size-xs': 24px,
    'height': 54px,
    //Same as figma
    'weight': 600,
    //Same as figma
    'style': normal,
    //Same as figma
    'family': Poppins,
    //Same as figma
  ),
  'h4': (
    'size-lg': 28px,
    //Same as figma
    'size-md': 24px,
    'size-sm': 22px,
    'size-xs': 20px,
    'height': 42px,
    //Same as figma
    'weight': 600,
    //Same as figma
    'style': normal,
    //Same as figma
    'family': Poppins,
    //Same as figma
  ),
  'h5': (
    'size-lg': 24px,
    //Same as figma
    'size-md': 22px,
    'size-sm': 18px,
    'size-xs': 12px,
    'height': 36px,
    //Same as figma
    'weight': 600,
    //Same as figma
    'style': normal,
    'family': Poppins,
    //Same as figma
  ),
  'h6': (
    'size-lg': 16px,
    'size-md': 14px,
    'size-sm': 12px,
    'size-xs': 10px,
    'height': 32px,
    'weight': 400,
    'style': normal,
    'family': Poppins,
  ),
  'h6Mobile': (
    'size-lg': 16px,
    'size-md': 14px,
    'size-sm': 12px,
    'size-xs': 12px,
    'height': 40px,
    'weight': 600,
    'style': normal,
    'family': Poppins,
  ),
  'p': (
    'size-lg': 18px,
    //Same as figma
    'size-md': 16px,
    'size-sm': 14px,
    'size-xs': 14px,
    'height': 25px,
    //Same as figma
    'weight': 400,
    //Same as figma
    'style': normal,
    //Same as figma
    'family': OpenSans,
    //Same as figma
  ),
  'small': (
    //in figma is sp
    'size-lg': 14px,
    //Same as figma
    'size-md': 12px,
    'size-sm': 12px,
    'size-xs': 12px,
    'height': 19px,
    //Same as figma
    'weight': 400,
    //Same as figma
    'style': normal,
    //Same as figma
    'family': OpenSans,
    //Same as figma
  ),
  'smallMobile': (
    'size-lg': 14px,
    'size-md': 12px,
    'size-sm': 12px,
    'size-xs': 12px,
    'height': 14px,
    'weight': 400,
    'style': normal,
    'family': Poppins,
  ),
  'label': (
    'size-lg': 12px,
    //Same as figma
    'size-md': 12px,
    'size-sm': 12px,
    'size-xs': 12px,
    'height': 16px,
    //Same as figma
    'weight': 400,
    //Same as figma
    'style': normal,
    //Same as figma
    'family': OpenSans,
    //Same as figma
  ),
  'displayXL': (
    'size-xl': 72px,
    'size-lg': 72px,
    'size-md': 72px,
    'size-sm': 72px,
    'height': 112px,
    'weight': 0,
    'style': normal,
    'family': PoppinsLight,
  ),
  'displayXS': (
    'size-xl': 48px,
    'size-lg': 48px,
    'size-md': 48px,
    'size-sm': 48px,
    'height': 72px,
    'weight': 0,
    'style': normal,
    'family': PoppinsLight,
  ),
  'displayBXL': (
    'size-xl': 72px,
    'size-lg': 72px,
    'size-md': 72px,
    'size-sm': 72px,
    'height': 112px,
    'weight': 0,
    'style': normal,
    'family': PoppinsBold,
  ),
  'displayBXS': (
    'size-xl': 48px,
    'size-lg': 48px,
    'size-md': 48px,
    'size-sm': 48px,
    'height': 72px,
    'weight': 0,
    'style': normal,
    'family': PoppinsBold,
  ),
  'headlineXL': (
    'size-xl': 48px,
    'size-lg': 48px,
    'size-md': 48px,
    'size-sm': 48px,
    'height': 72px,
    'weight': 0,
    'style': normal,
    'family': PoppinsBold,
  ),
  'headlineXS': (
    'size-xl': 35px,
    'size-lg': 35px,
    'size-md': 35px,
    'size-sm': 35px,
    'height': 54px,
    'weight': 0,
    'style': normal,
    'family': PoppinsBold,
  ),
  'titleXL': (
    'size-xl': 36px,
    'size-lg': 36px,
    'size-md': 36px,
    'size-sm': 36px,
    'height': 54px,
    'weight': 0,
    'style': normal,
    'family': PoppinsSemiBold,
  ),
  'titleXS': (
    'size-xl': 26px,
    'size-lg': 26px,
    'size-md': 26px,
    'size-sm': 26px,
    'height': 40px,
    'weight': 0,
    'style': normal,
    'family': PoppinsSemiBold,
  ),
  'bodyBigXL': (
    'size-xl': 26px,
    'size-lg': 26px,
    'size-md': 26px,
    'size-sm': 26px,
    'height': 40px,
    'weight': 0,
    'style': normal,
    'family': Poppins,
  ),
  'bodyBigXS': (
    'size-xl': 20px,
    'size-lg': 20px,
    'size-md': 20px,
    'size-sm': 20px,
    'height': 30px,
    'weight': 0,
    'style': normal,
    'family': Poppins,
  ),
  'bodyBigBXL': (
    'size-xl': 26px,
    'size-lg': 26px,
    'size-md': 26px,
    'size-sm': 26px,
    'height': 40px,
    'weight': 0,
    'style': normal,
    'family': PoppinsBold,
  ),
  'bodyBigBXS': (
    'size-xl': 20px,
    'size-lg': 20px,
    'size-md': 20px,
    'size-sm': 20px,
    'height': 30px,
    'weight': 0,
    'style': normal,
    'family': PoppinsBold,
  ),
  'label1XL': (
    'size-xl': 24px,
    'size-lg': 24px,
    'size-md': 24px,
    'size-sm': 24px,
    'height': 36px,
    'weight': 0,
    'style': normal,
    'family': PoppinsSemiBold,
  ),
  'label1XS': (
    'size-xl': 18px,
    'size-lg': 18px,
    'size-md': 18px,
    'size-sm': 18px,
    'height': 26px,
    'weight': 0,
    'style': normal,
    'family': PoppinsSemiBold,
  ),
  'body1XL': (
    'size-xl': 20px,
    'size-lg': 20px,
    'size-md': 20px,
    'size-sm': 20px,
    'height': 30px,
    'weight': 0,
    'style': normal,
    'family': Poppins,
  ),
  'body1XS': (
    'size-xl': 16px,
    'size-lg': 16px,
    'size-md': 16px,
    'size-sm': 16px,
    'height': 24px,
    'weight': 0,
    'style': normal,
    'family': Poppins,
  ),
  'body2XL': (
    'size-xl': 16px,
    'size-lg': 16px,
    'size-md': 16px,
    'size-sm': 16px,
    'height': 28px,
    'weight': 0,
    'style': normal,
    'family': Poppins,
  ),
  'button1Xl': (
    'size-xl': 18px,
    'size-lg': 18px,
    'size-md': 18px,
    'size-sm': 18px,
    'height': 28px,
    'weight': 0,
    'style': normal,
    'family': PoppinsMedium,
  ),
  'button1XS': (
    'size-xl': 14px,
    'size-lg': 14px,
    'size-md': 14px,
    'size-sm': 14px,
    'height': 17px,
    'weight': 0,
    'style': normal,
    'family': PoppinsMedium,
  ),
  'button2Xl': (
    'size-xl': 16px,
    'size-lg': 16px,
    'size-md': 16px,
    'size-sm': 16px,
    'height': 24px,
    'weight': 0,
    'style': normal,
    'family': PoppinsMedium,
  ),
  'button2XS': (
    'size-xl': 14px,
    'size-lg': 14px,
    'size-md': 14px,
    'size-sm': 14px,
    'height': 17px,
    'weight': 0,
    'style': normal,
    'family': PoppinsMedium,
  ),
);

@each $name, $font in $fonts {
  .sb-text-#{$name} {
    font-family: map-get($fonts, $name, 'family');
    font-style: map-get($fonts, $name, 'style');
    font-weight: map-get($fonts, $name, 'weight');
    font-size: map-get($fonts, $name, 'size-lg');
    line-height: map-get($fonts, $name, 'height');

    @media (max-width: $breakpoint-md) {
      font-size: map-get($fonts, $name, 'size-md');
    }

    @media (max-width: $breakpoint-sm) {
      font-size: map-get($fonts, $name, 'size-sm');
    }

    @media (max-width: $breakpoint-xs) {
      font-size: map-get($fonts, $name, 'size-xs');
    }
  }
}

// Container
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $breakpoint-xs) {
    max-width: 1500px;
  }
  @media (min-width: $breakpoint-sm) {
    max-width: 1500px;
  }
  @media (min-width: $breakpoint-md) {
    max-width: 1500px;
  }
  @media (min-width: $breakpoint-lg) {
    max-width: 1500px;
  }
}

.sb-text-regular {
  font-weight: normal !important;
}

.sb-text-bold {
  font-weight: 700 !important;
}
.sb-text-bold-poppins {
  font-weight: 700 !important;
  font-family: PoppinsBold;
}
.sb-text-bold-openSans {
  font-weight: 700 !important;
  font-family: OpenSansBold;
}

.sb-text-poppins {
  font-family: Poppins;
}

.sb-text-underline {
  text-decoration: underline;
}

.sb-br-8 {
  border-radius: 8px;
}

.sb-br-10 {
  border-radius: 10px;
}

.sb-card-border {
  border: 2px solid #2d354c;
  box-sizing: border-box;
  border-radius: 10px;
}

// Styles limited to landing only

.sb-card {
  border-radius: 16px;
  border: 3px solid #162044;
}

.sb-dialog-card {
  border: 2px solid #2d354c;
  border-radius: 25px !important;
}

.no-text-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flip-list-enter-active,
.flip-list-leave-active,
.flip-list-move {
  transition: 800ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.flip-list-enter {
  opacity: 0;
  transform: translateX(50px) scaleY(0.5);
}

.flip-list-enter-to {
  opacity: 1;
  transform: translateX(0) scaleY(1);
}

.flip-list-leave-active {
  position: absolute;
}

.flip-list-leave-to {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;
}
